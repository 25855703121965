import { memo, useEffect } from "react";
import { FunIdLogoWithText } from "../../icons/fun-id-logo-with-text";
import { io } from "socket.io-client";
import styles from "./main-page.module.css";
import { MobileContent } from "./MobileСontent";
import { DesktopContent } from "./DesktopСontent";
import { NoAppContent } from "./NoAppСontent";
import { useSearchParams } from "react-router-dom";

interface InlineStyle {
  app: {
    background: string
  },
  qr: {
    bgColor: string,
    fgColor: string
  },
  qrTitle: {
    display?: string
  },
  mobileLinkButton: {
    border?: string,
    color?: string
  }
}

export type PageContenProps = {
  currentURL: string;
  inlineStyle: InlineStyle
};

export type PagesProps = {
  isMobilePlatform: boolean;
};

// FYI: LEGACY FRUMZI FRONT
const inlineStyleFrumzi:InlineStyle = {
  app: {
    background:  "#0E1147"
  },
  qr: {
    bgColor: "#0E1147",
    fgColor: "white"
  },
  qrTitle: {},
  mobileLinkButton: {}
};

const InlineStyleCustom = (color: string | null | undefined): InlineStyle => {
  return {
    app: {
      background: "transparent"
    },
    qr: {
      bgColor: "transparent",
      fgColor: color || "white"
    },
    qrTitle: {
      display: "none"
    },
    mobileLinkButton: {
      border: color ? `1px solid ${color}`: "1px solid white",
      color: color || "white"
    }
  };
};

export const MainPage = memo((props: PagesProps) => {
  const { isMobilePlatform } = props;
  const currentURL = window.location.href;
  const [searchParams] = useSearchParams();

  const noApp = searchParams.get("noapp") === "true";

  const isCustomStyles = !!searchParams.get("theme");
  const themeColor = searchParams.get("theme");

  const inlineStyle: InlineStyle = isCustomStyles ? InlineStyleCustom(themeColor) : inlineStyleFrumzi;

  useEffect(() => {
    return void async function () {
      const envResponse = await fetch('/env');
      const { apiURL } = await envResponse.json();
      const socketUrl = apiURL;
      const socket = io(socketUrl!);
      const transaction_id = searchParams.get("transaction_id");

      // TODO: clear after test
      console.log("socket-url:", socketUrl);
      console.log("subscribe to transaction_id:", transaction_id);
      socket.emit("transaction:subscribe", {
        transactionId: transaction_id,
      });

      socket.on(
          "transaction:status",
          (data: { status: string; redirectUrl: string; error: string }) => {
            // TODO: clear after test
            console.log("Transaction status:", data);

            if (data.error === "EMAIL_ALREADY_IN_USE") {
              console.log("do nothing on EMAIL_ALREADY_IN_USE now");
              return;
            }

            if (window && data?.redirectUrl) {
              window.location.href = data.redirectUrl;
            }
          }
      );
    }();
  }, [searchParams]);

  return (
    <div className={styles.app} style={inlineStyle.app}>
      {isMobilePlatform ? (
        noApp ? (
          <NoAppContent currentURL={currentURL} inlineStyle={inlineStyle} />
        ) : (
          <MobileContent currentURL={currentURL} inlineStyle={inlineStyle} />
        )
      ) : (
        <DesktopContent currentURL={currentURL} inlineStyle={inlineStyle} />
      )}
    </div>
  );
});

// wazamba.com = #6e3823
// rabona.com = white