import { memo, forwardRef, SVGProps, Ref } from "react";

export const FunIdLogo = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <rect width="36" height="36" rx="18" fill="#3F38DD" />
      <ellipse
        cx="19.5923"
        cy="26.3374"
        rx="2.86894"
        ry="2.86894"
        transform="rotate(-19.5178 19.5923 26.3374)"
        fill="#FFD339"
      />
      <path
        d="M11.4382 13.6309C10.6612 11.8561 11.554 9.79486 13.3801 9.14756C15.2062 8.50027 17.1978 9.53902 17.7121 11.407L19.8614 19.2137C20.2446 20.6055 19.4876 22.0569 18.127 22.5392C16.7664 23.0215 15.2643 22.3709 14.6854 21.0485L11.4382 13.6309Z"
        fill="#FE26A8"
      />
      <path
        d="M18.3522 10.0253C18.0338 8.82279 18.6913 7.57719 19.8637 7.1616L22.1306 6.35807C23.4628 5.88584 24.9245 6.59026 25.3852 7.92652C25.8823 9.36829 25.0077 10.9204 23.517 11.2421L21.166 11.7494C19.9181 12.0187 18.6789 11.2594 18.3522 10.0253Z"
        fill="#00D698"
      />
      <rect
        x="19.7744"
        y="13.8359"
        width="4.7994"
        height="3.81211"
        rx="1.90605"
        transform="rotate(-13.2874 19.7744 13.8359)"
        fill="#00D698"
      />
    </svg>
  ))
);
