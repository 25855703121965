import { memo, forwardRef, SVGProps, Ref } from "react";

export const FunIdTextLogo = memo(
  forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
      width="80"
      height="18"
      viewBox="0 0 80 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M7.79084 3.88434L7.00414 7.79408H14.8457L14.059 11.6784H6.24282L5.02471 17.7715H0L3.55282 0H17.4596L16.6729 3.88434H7.79084Z"
        fill="#3F38DD"
      />
      <path
        d="M32.9622 4.01128L30.2214 17.7715H25.6535L25.9326 16.3498C25.3405 16.9083 24.6722 17.323 23.9278 17.5938C23.2003 17.8646 22.4475 18 21.6693 18C20.1635 18 18.9623 17.5938 18.0657 16.7814C17.1859 15.969 16.7461 14.8096 16.7461 13.3032C16.7461 12.7447 16.8053 12.1777 16.9237 11.6023L18.4463 4.01128H23.268L21.8469 11.1453C21.7792 11.4669 21.7454 11.78 21.7454 12.0846C21.7454 13.2863 22.3629 13.8872 23.5979 13.8872C24.4269 13.8872 25.1121 13.6333 25.6535 13.1255C26.1949 12.6008 26.5671 11.8307 26.7701 10.8152L28.1405 4.01128H32.9622Z"
        fill="#3F38DD"
      />
      <path
        d="M44.6708 3.78279C46.1765 3.78279 47.3777 4.189 48.2744 5.00141C49.188 5.7969 49.6448 6.94781 49.6448 8.45416C49.6448 9.01269 49.5856 9.57969 49.4671 10.1551L47.9445 17.7715H43.1228L44.5439 10.6121C44.6116 10.3244 44.6454 10.0197 44.6454 9.69817C44.6454 8.47955 44.011 7.87024 42.7421 7.87024C41.8962 7.87024 41.2026 8.13258 40.6612 8.65726C40.1198 9.16502 39.7392 9.93512 39.5192 10.9676L38.1488 17.7715H33.3272L36.0679 4.01128H40.6358L40.3567 5.433C40.9488 4.87447 41.6171 4.4598 42.3615 4.189C43.1059 3.9182 43.8757 3.78279 44.6708 3.78279Z"
        fill="#3F38DD"
      />
      <path
        d="M54.7783 0H59.803L56.2502 17.7715H51.2255L54.7783 0Z"
        fill="#3F38DD"
      />
      <path
        d="M63.3779 0H71.0926C72.9197 0 74.5016 0.304655 75.8381 0.913964C77.1747 1.50635 78.1982 2.36954 78.9088 3.50353C79.6363 4.62059 80 5.94076 80 7.46403C80 9.52891 79.5263 11.3399 78.5789 12.897C77.6484 14.4542 76.3457 15.6559 74.6708 16.5021C72.9959 17.3484 71.0756 17.7715 68.9101 17.7715H59.825L63.3779 0ZM69.0624 13.7602C70.2805 13.7602 71.3294 13.5063 72.2092 12.9986C73.1058 12.4739 73.7826 11.763 74.2393 10.866C74.6961 9.95205 74.9245 8.91961 74.9245 7.76869C74.9245 6.567 74.5523 5.64457 73.8079 5.00141C73.0635 4.34133 71.9723 4.01128 70.5343 4.01128H67.5905L65.6618 13.7602H69.0624Z"
        fill="#3F38DD"
      />
    </svg>
  ))
);
