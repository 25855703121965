import QRCode from "react-qr-code";
import { FunIdLogo } from "../../icons/fun-id-logo";
import styles from "./main-page.module.css";
import { memo } from "react";
import { PageContenProps } from "./MainPage";

export const MobileContent = memo((props: PageContenProps) => {
  const { currentURL, inlineStyle } = props;

  return (
    <>
      <span className={styles.qrTitle} style={inlineStyle.qrTitle}>
        Please click the button below to proceed your operation or scan QR code
        on another device
      </span>
      <div className={styles.qrWrapper}>
        <QRCode
          size={100}
          value={currentURL}
          viewBox={`0 0 256 256`}
          className={styles.qr}
          fgColor={inlineStyle.qr.fgColor}
          bgColor={inlineStyle.qr.bgColor}
        />
      </div>
      <a
        href={currentURL + "&noapp=true"}
        target="_blank"
        className={styles.mobileLinkButton}
        rel="noreferrer"
        style={inlineStyle.mobileLinkButton}
      >
        <FunIdLogo width="2.25rem" height="2.25rem" />
        <span>Proceed with FunID</span>
      </a>
    </>
  );
});
