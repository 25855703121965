import { memo, useLayoutEffect } from "react";
import { FunIdLogo } from "../../icons/fun-id-logo";
import styles from "./main-page.module.css";
import { PageContenProps } from "./MainPage";

export const NoAppContent = memo((props: PageContenProps) => {
  const { currentURL } = props;

  useLayoutEffect(() => {
    window.location.href = "https://casinoidapp.page.link/store";
  }, []);

  return (
    <>
      <span className={styles.qrTitle}>
        You don't have the FunID app, please download to proceed your operation
      </span>
      <a
        href="https://casinoidapp.page.link/store"
        className={styles.mobileLinkButton}
      >
        <FunIdLogo width="2.25rem" height="2.25rem" />
        <span>Download FunID app</span>
      </a>
      <span style={{ marginTop: "0.5rem" }}>or</span>
      <a
        href={currentURL.replace("&noapp=true", "")}
        className={styles.tryAgainButton}
      >
        Try again
      </a>
    </>
  );
});
