import { Route, Routes } from "react-router-dom";
import { FailurePage, MainPage, SuccessPage } from "./pages";
import { RootPage } from "./pages/RootPage";

export const App = () => {
  const isMobilePlatform = !!(
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );

  return (
    <Routes>
      <Route path="/" element={<RootPage />} />
      <Route
        path="process"
        element={<MainPage isMobilePlatform={isMobilePlatform} />}
      />
      <Route
        path="payment/success"
        element={<SuccessPage isMobilePlatform={isMobilePlatform} />}
      />
      <Route
        path="payment/failure"
        element={<FailurePage isMobilePlatform={isMobilePlatform} />}
      />
    </Routes>
  );
};
