import { memo } from "react";
import { FunIdTextLogo } from "../icons/fun-id-text-logo";
import styles from "./status-pages.module.css";
import { PagesProps } from "./main-page/MainPage";
import cx from "classnames";

export const SuccessPage = memo((props: PagesProps) => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.logoWrapper}>
        <div className={styles.funIdImg} />
        <FunIdTextLogo />
      </div>
      <div
        className={cx(styles.title, {
          [styles.mobileTitle]: props.isMobilePlatform,
        })}
      >
        Your operation was processed <br /> successfully
      </div>
    </div>
  );
});
