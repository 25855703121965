import { memo } from "react";
import { FunIdTextLogo } from "../icons/fun-id-text-logo";
import QRCode from "react-qr-code";
import styles from "./status-pages.module.css";
import { PagesProps } from "./main-page/MainPage";
import cx from "classnames";

export const FailurePage = memo((props: PagesProps) => {
  const currentURL = window.location.href;

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.logoWrapper}>
        <div className={styles.funIdImg} />
        <FunIdTextLogo />
      </div>
      <div
        className={cx(styles.title, {
          [styles.mobileTitle]: props.isMobilePlatform,
        })}
      >
        Your operation is failed
      </div>
      {props.isMobilePlatform ? (
        <a href={currentURL} className={styles.tryAgainButton}>
          Try again
        </a>
      ) : (
        <div className={styles.qrBlockWrapper}>
          <div className={styles.qrTitle}>
            To try again, please scan QR code
          </div>
          <div className={styles.qrWrapper}>
            <QRCode
              size={100}
              value={currentURL}
              viewBox={`0 0 256 256`}
              className={styles.qr}
              fgColor={"#1B3063"}
            />
          </div>
        </div>
      )}
    </div>
  );
});
