import QRCode from "react-qr-code";
import styles from "./main-page.module.css";
import { memo } from "react";
import { PageContenProps } from "./MainPage";

export const DesktopContent = memo((props: PageContenProps) => {
  const { currentURL, inlineStyle } = props;
  return (
    <div className={styles.desktopMainWrapper}>
      <span className={styles.qrTitle} style={inlineStyle.qrTitle}>
        Please scan the QR- code and confirm the operation in FunID app
      </span>
      <div className={styles.qrWrapper}>
        <QRCode
          size={150}
          value={currentURL}
          viewBox={`0 0 256 256`}
          className={styles.qr}
          fgColor={inlineStyle.qr.fgColor}
          bgColor={inlineStyle.qr.bgColor}
        />
      </div>
    </div>
  );
});
